import styled from "styled-components";

import { etWhite } from "../../shared/colors";

export namespace sc {
  export const Container = styled.div`
    display: grid;
    grid-gap: 16px;
    width: 224px;
    max-width: 224px;
    padding: 12px;
    border-radius: 6px;
    background-color: ${etWhite};
  `;

  export const MenuItem = styled.div<{ useDefaultCursor?: boolean }>`
    cursor: ${(props) => (props.useDefaultCursor ? "default" : "pointer")};
    & > button {
      width: 200px;
    }
  `;
}
